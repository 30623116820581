import React from "react"
import Helmet from "react-helmet"

function Noindex() {
  return (
    <Helmet
      meta={[
        {
          name: `robots`,
          content: `noindex`,
        },
      ]}
    />
  )
}

export default Noindex
